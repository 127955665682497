// App
@import "vars";



// FavIcon
path.favIcon {
    fill: black;
}

@media (prefers-color-scheme: dark) {
    path.favIcon {
        fill: white;
    }
}





// Reset
:root {
    font-size: 16px;
    line-height: 1.375em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    font-weight: 400;
}

html {
    background: var(--color-background);
    color: var(--color-foreground);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: var(--font-family);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

html,
body {
    font-size: 16px;
    position: relative; // Safari Hack (blerg!)
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: inherit;
}

body,
main,
article {
    padding: 0;
    margin: 0;
    min-height: 100%;
    height: 100%;
    width: 100%;
}

main,
header {
    // max-width: 60em;
    margin: 0 auto;
}

main {
    height: 100dvh;
    display: grid;
    overflow: hidden;

    & article {
        overflow: scroll;
    }
}



// Prefer This
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

main {
    margin: 0 auto;
}

img {
    max-width: 100%;
    height: auto;
}



#l00k__fan {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;

    span {
        width: clamp(10em, 40%, 40em);
        aspect-ratio: 1;
        display: block;

        svg.fanIll {
            width: 100%;
            height: 100%;

            .fan-movement {
                transform-origin: center;
                will-change: transform;
            }

            path {
                fill: var(--color-black);
            }

            #circleBG {
                opacity: 0;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

@container (max-width: 699px) {

    #loop.__fan .bar #left,
    #loop.__fan .bar #right {
        width: clamp(10em, 60%, 30em);
    }
}


main {
    display: grid;
    overflow: hidden;
    height: 100dvh;

    & .l00k__nav {
        align-content: start;

        h1 {
            filter: url(#goo2);
        }
    }

    & button.toggle__l00k__nav {
        top: unset;
        position: absolute;
        right: calc(var(--space) * 1.675);
        bottom: calc(var(--space) * 1.675);

    }

    &.w__l00k__nav {
        --right: 22em;

        & button.toggle__l00k__nav {
            height: 2.5em;
            width: 2.5em;
            padding: 1.5em;
            position: fixed;
            right: calc(var(--space) * 0.5);
            top: calc(var(--space) * 1);
            bottom: unset;
            border-radius: 0.125em;
        }
    }

    &.w__scroll article {
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.l00k__body {
    display: grid;
    container-type: inline-size;
    position: relative;
    grid-template-columns: 1fr;
   
    & .l00k__name {
        margin-bottom: 0;
        display: grid;
        align-items: center;

        >* {
            margin-bottom: 0;
            line-height: 1.375em;
            padding-bottom: 0.375em;
            padding-top: 0.75em;
            filter: url(#goo3);
            text-align: center;
            letter-spacing: 0.15em;
            font-weight: 400;

        }
    }

    & #l00k__head {
        display: grid;
        width: 100%;
        display: grid;
        justify-items: center;

        >* {
            max-width: clamp(30em, 60vmin, 100em);
            display: block;
            margin: 0 auto;
        }
    }

    & #l00k__cols {
        
        >* {
            max-width: clamp(30em, 90vw, 80em);
            display: block;
            margin: 0 auto;
            transform: scale(1.075);
            transform-origin: center;
        }
    }

    & #l00k__fan {
        margin-top: -1em;
        margin-bottom: -2em;
    }

    @container (min-width: 699px) {
        display: grid;
        grid-template-columns: 1fr 1fr; // Define two columns

        & .l00k__name {
            >* {
                text-align: left;
                padding-top: 0;
                padding-left: 0.5em;
            }
        }

        & #l00k__head {
            >* {
                margin: -5vh auto -5vh;
            }
        }

        & #l00k__fan {
            margin-top: unset;
            margin-bottom: unset;

            >* {
                margin-right: -1em;
            }
        }

        // Fan bowTie
        &>*:nth-of-type(3) {
            grid-row: 1;
            grid-column: 2 / 3; // Take up second column
            display: grid;
            place-items: end !important;
        }

        &>*:nth-of-type(2) {
            grid-row: 2;
            grid-column: 1 / span 2; // Span both columns
            width: 100%;

            >* {
                width: 100%;
                margin-bottom: -2em;
            }
        }

        &>*:nth-of-type(4) {
            grid-row: 3;
            grid-column: 1 / span 2; // Span both columns
            width: 100%;
        }
    }
}

section.l00k__address {
    text-align: center;
    border: 1px solid var(--color-foreground);
    margin: var(--space) auto clamp(calc(var(--space) * 2), 5vmin, calc(var(--space) * 2));
    width: calc(100% - var(--space) * 2);
    border-top-color: color-mix(in srgb, var(--color-foreground) 50%, transparent 100%);
    border-left-color: color-mix(in srgb, var(--color-foreground) 50%, transparent 100%);
    max-width: clamp(30em, 90vw, 80em);

    *[data-heading] {
        filter: url(#goo3);
        margin-bottom: 0.125em;
        font-family: var(--font-mono);
    }
    a {
        color: var(--color-foreground);
        text-decoration: underline;
        text-underline-offset: 0.1em;
        text-decoration-color: var(--color-foreground);
        transition: all calc(var(--animation-step) * 0.75) cubic-bezier(0.215, 0.610, 0.355, 1);
        
        &:hover {
            color: var(--color-primary);
            text-decoration: underline;
            text-underline-offset: 0.25em;
            text-decoration-color: var(--color-primary);
        }
    }
}