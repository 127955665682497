#l00k__head {
	margin: 0 auto 0;
	width: 100%;
	height:100%;
	z-index: 2;
	max-width: 900px;
	position: relative;
	padding: 0;
	
	& > span {
		display: block;
	    position: relative;
	    width: 100%;
	}

	& svg {
		width: 100%;

		& .headQuarters path {
			stroke:transparent;
			fill:transparent
		}
	}
}

.dailGroup {
	opacity:1;
}


// Remove This

.dailBack {
	fill:none;
}

.northDail {
	fill: var(--color-white);
}

.southDail {
	fill: color-mix(in srgb, var(--color-foreground) 33%, var(--color-background));
}

.fanCol {
	& #l00k__head {
			
			& .headQuarters {
				visibility: hidden !important;
			}

			& .conduitHead {
				fill: white !important
			}
			& .northDail {
				fill: color-mix(in srgb, var(--color-green) 80%, var(--color-black));
			}
			& .dailPin {
				fill: white !important
			}

			& .southDail {
    			fill: color-mix(in srgb, var(--color-foreground) 33%, var(--color-background));
    			opacity: 0.125;
			}
		}
}