@media (width >= 400px) {
  :root {
    --space: calc(var(--font-size) * 1.5);
  }
}

@media (width >= 800px) {
  :root {
    --space: calc(var(--font-size) * 1.875);
  }
}

@media (width >= 1060px) {
  :root {
    --space: calc(var(--font-size) * 2);
  }
}

.hm-button, button.toggle__l00k__nav, nav.hm__nav button.toggle__l00k__nav, nav.hm__nav a, nav.hm__nav h1, nav.hm__nav .hm-button {
  box-sizing: border-box;
  color: inherit;
  font-size: var(--font-size);
  font-family: inherit;
  font-weight: 400;
  line-height: var(--font-line-height-body);
  cursor: pointer;
  text-align: center;
  text-transform: none;
  border: var(--border-width) solid transparent;
  background-color: #0000;
  outline: 0;
  align-items: center;
  padding: 0;
  display: flex;
  overflow: visible;
}

.hm-button abbr, button.toggle__l00k__nav abbr, nav.hm__nav a abbr, nav.hm__nav h1 abbr, nav.hm__nav .hm-button abbr {
  pointer-events: none;
  width: 1em;
  height: 1em;
  margin-left: .675em;
}

.hm-button abbr.iconExample svg, button.toggle__l00k__nav abbr.iconExample svg, nav.hm__nav a abbr.iconExample svg, nav.hm__nav h1 abbr.iconExample svg {
  transform-origin: center;
  width: 100%;
}

.hm-button:focus-visible, button.toggle__l00k__nav:focus-visible, nav.hm__nav a:focus-visible, nav.hm__nav h1:focus-visible, nav.hm__nav .hm-button:focus-visible {
  box-shadow: var(--focus-box-shadow);
  outline-color: purple;
}

.hm-button:focus:not(:focus-visible), button.toggle__l00k__nav:focus:not(:focus-visible), nav.hm__nav a:focus:not(:focus-visible), nav.hm__nav h1:focus:not(:focus-visible) {
  box-shadow: none;
  outline: none;
}

.hm-button:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

button.toggle__l00k__nav:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

nav.hm__nav a:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

nav.hm__nav h1:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

.hm-button:active, button.toggle__l00k__nav:active, nav.hm__nav a:active, nav.hm__nav h1:active, nav.hm__nav .hm-button:active {
  transform: scale(.97);
}

.hm-button--dope, button.toggle__l00k__nav, nav.hm__nav a, nav.hm__nav h1, nav.hm__nav .hm-button, nav.hm__nav button.toggle__l00k__nav {
  color: var(--color-background);
  background-color: var(--color-foreground);
  letter-spacing: .01em;
  transition: transform var(--animation-step) ease-in-out, box-shadow var(--animation-step) ease-in-out, background-color var(--animation-step) ease-in-out;
  text-align: center;
  font-weight: 600;
  line-height: 1em;
  font-size: var(--font-size);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 1.125em;
  display: inline-flex;
  text-decoration: none !important;
}

.hm-button--dope span, button.toggle__l00k__nav span, nav.hm__nav a span, nav.hm__nav h1 span, nav.hm__nav .hm-button span {
  width: 100%;
}

.hm-button--dope:hover, button.toggle__l00k__nav:hover, nav.hm__nav a:hover, nav.hm__nav h1:hover, nav.hm__nav .hm-button:hover {
  background-color: var(--color-primary);
}

.hm-button--dope:active, button.toggle__l00k__nav:active, nav.hm__nav a:active, nav.hm__nav h1:active, nav.hm__nav .hm-button:active {
  box-shadow: 0 0 0 .25em var(--color-primary);
  outline: 0;
}

.hm-button--dope.--hm-publish:focus, button.--hm-publish.toggle__l00k__nav:focus, nav.hm__nav a.--hm-publish:focus, nav.hm__nav h1.--hm-publish:focus, nav.hm__nav .--hm-publish.hm-button:focus {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  outline-offset: 0em !important;
  outline: .375em solid var(--color-primary) !important;
}

.hm-button[data-size="xs"], nav.hm__nav a[data-size="xs"], nav.hm__nav h1[data-size="xs"], nav.hm__nav [data-size="xs"].hm-button, .hm-button.--xs, nav.hm__nav a.--xs, nav.hm__nav h1.--xs, nav.hm__nav .--xs.hm-button, button[data-size="xs"], button.--xs, a[data-size="xs"], a.--xs {
  padding: .675em .75em;
  font-size: 1em;
  font-weight: 400;
}

.hm-button[data-size="xs"].--round, nav.hm__nav h1[data-size="xs"].--round, .hm-button.--xs.--round, nav.hm__nav h1.--xs.--round, button[data-size="xs"].--round, button[data-size="xs"].toggle__l00k__nav, button.--xs.--round, button.--xs.toggle__l00k__nav, a[data-size="xs"].--round, a.--xs.--round {
  padding: .675em 1.35em;
}

.hm-button[data-size="xs"].--circle, nav.hm__nav h1[data-size="xs"].--circle, .hm-button.--xs.--circle, nav.hm__nav h1.--xs.--circle, button[data-size="xs"].--circle, button.--xs.--circle, a[data-size="xs"].--circle, a.--xs.--circle {
  padding: .675em .875em;
}

.hm-button[data-size="sm"], .hm-button.--sm, nav.hm__nav .hm-button, nav.hm__nav button.toggle__l00k__nav, nav.hm__nav a, nav.hm__nav h1, button[data-size="sm"], button.--sm, a[data-size="sm"], a.--sm {
  padding: .5em .75em;
  font-size: 1em;
  font-weight: 400;
}

.hm-button[data-size="sm"][data-round], .hm-button[data-size="sm"].--round, .hm-button.--sm[data-round], nav.hm__nav .hm-button[data-round], nav.hm__nav h1[data-round], .hm-button.--sm.--round, nav.hm__nav .hm-button.--round, nav.hm__nav button.toggle__l00k__nav, nav.hm__nav h1.--round, button[data-size="sm"][data-round], button[data-size="sm"].--round, button[data-size="sm"].toggle__l00k__nav, button.--sm[data-round], button.--sm.--round, button.--sm.toggle__l00k__nav, a[data-size="sm"][data-round], a[data-size="sm"].--round, a.--sm[data-round], nav.hm__nav a[data-round], a.--sm.--round, nav.hm__nav a.--round {
  padding-left: .875em;
  padding-right: .875em;
}

.hm-button[data-size="md"], nav.hm__nav a[data-size="md"], nav.hm__nav h1[data-size="md"], nav.hm__nav [data-size="md"].hm-button, .hm-button.--md, nav.hm__nav a.--md, nav.hm__nav h1.--md, nav.hm__nav .--md.hm-button, button[data-size="md"], button.--md, a[data-size="md"], a.--md {
  padding: 1.125em 1.5em;
}

.hm-button[data-size="lg"], nav.hm__nav a[data-size="lg"], nav.hm__nav h1[data-size="lg"], nav.hm__nav [data-size="lg"].hm-button, .hm-button.--lg, nav.hm__nav a.--lg, nav.hm__nav h1.--lg, nav.hm__nav .--lg.hm-button, button[data-size="lg"], button.--lg, a[data-size="lg"], a.--lg {
  padding: 1.375em 1.5em;
  font-size: 1.125em;
}

.hm-button[data-size="xl"], nav.hm__nav a[data-size="xl"], nav.hm__nav h1[data-size="xl"], nav.hm__nav [data-size="xl"].hm-button, .hm-button.--xl, nav.hm__nav a.--xl, nav.hm__nav h1.--xl, nav.hm__nav .--xl.hm-button, button[data-size="xl"], button.--xl, button.toggle__l00k__nav, a[data-size="xl"], a.--xl {
  padding: 1.25em;
  font-size: 1.375em;
  font-weight: 800;
  line-height: 1.125em;
}

.hm-button[data-variant="primary"], nav.hm__nav a[data-variant="primary"], nav.hm__nav h1[data-variant="primary"], nav.hm__nav [data-variant="primary"].hm-button, .hm-button.--primary, nav.hm__nav a.--primary, nav.hm__nav h1.--primary, nav.hm__nav .--primary.hm-button, button[data-variant="primary"], button.--primary, a[data-variant="primary"], a.--primary {
  background-color: var(--color-primary);
  color: var(--color-background);
  transition: border-radius calc(var(--animation-step) * 3) cubic-bezier(.6, -1, .25, 3), box-shadow calc(var(--animation-step) * 2) ease-in-out, transform calc(var(--animation-step) * 3) cubic-bezier(.6, -1, .25, 3);
}

.hm-button[data-variant="primary"]:hover, nav.hm__nav h1[data-variant="primary"]:hover, .hm-button.--primary:hover, nav.hm__nav h1.--primary:hover, button[data-variant="primary"]:hover, button.--primary:hover, a[data-variant="primary"]:hover, a.--primary:hover {
  color: var(--color-background);
  background-color: var(--color-primary);
  transform: scale(1.0125);
}

.hm-button[data-variant="primary"]:focus:not(:focus-visible), nav.hm__nav h1[data-variant="primary"]:focus:not(:focus-visible), .hm-button.--primary:focus:not(:focus-visible), nav.hm__nav h1.--primary:focus:not(:focus-visible), button[data-variant="primary"]:focus:not(:focus-visible), button.--primary:focus:not(:focus-visible), a[data-variant="primary"]:focus:not(:focus-visible), a.--primary:focus:not(:focus-visible) {
  box-shadow: none;
  outline: none;
}

.hm-button[data-variant="primary"]:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

nav.hm__nav h1[data-variant="primary"]:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

.hm-button.--primary:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

nav.hm__nav h1.--primary:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

button[data-variant="primary"]:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

button.--primary:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

a[data-variant="primary"]:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

a.--primary:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

.hm-button[data-variant="secondary"], nav.hm__nav a[data-variant="secondary"], nav.hm__nav h1[data-variant="secondary"], nav.hm__nav [data-variant="secondary"].hm-button, .hm-button.--secondary, nav.hm__nav a.--secondary, nav.hm__nav h1.--secondary, nav.hm__nav .--secondary.hm-button, button[data-variant="secondary"], button.--secondary, a[data-variant="secondary"], a.--secondary {
  background-color: var(--color-secondary);
  color: var(--color-background);
  border-color: var(--color-secondary);
  box-shadow: 0 0 0em 0px var(--color-tertiary);
}

.hm-button[data-variant="secondary"]:hover, nav.hm__nav h1[data-variant="secondary"]:hover, .hm-button.--secondary:hover, nav.hm__nav h1.--secondary:hover, button[data-variant="secondary"]:hover, button.--secondary:hover, a[data-variant="secondary"]:hover, a.--secondary:hover {
  color: var(--color-background);
  background-color: var(--color-foreground);
  border-color: var(--color-foreground);
}

.hm-button[data-variant="secondary"]:active, nav.hm__nav h1[data-variant="secondary"]:active, .hm-button.--secondary:active, nav.hm__nav h1.--secondary:active, button[data-variant="secondary"]:active, button.--secondary:active, a[data-variant="secondary"]:active, a.--secondary:active {
  border-color: var(--color-tertiary);
  box-shadow: 0 0 1.5em 1px var(--color-tertiary) !important;
}

.hm-button[data-variant="tertiary"], nav.hm__nav a[data-variant="tertiary"], nav.hm__nav h1[data-variant="tertiary"], nav.hm__nav [data-variant="tertiary"].hm-button, .hm-button.--tertiary, nav.hm__nav a.--tertiary, nav.hm__nav h1.--tertiary, nav.hm__nav .--tertiary.hm-button, button[data-variant="tertiary"], button.--tertiary, a[data-variant="tertiary"], a.--tertiary {
  background-color: var(--color-secondary);
  color: var(--color-primary-lm6);
  box-shadow: 0 0 0em 0px var(--color-secondary-a05);
}

.hm-button[data-variant="tertiary"]:hover, nav.hm__nav h1[data-variant="tertiary"]:hover, .hm-button.--tertiary:hover, nav.hm__nav h1.--tertiary:hover, button[data-variant="tertiary"]:hover, button.--tertiary:hover, a[data-variant="tertiary"]:hover, a.--tertiary:hover {
  color: var(--color-primary-lm6);
  background-color: var(--color-secondary-lp2);
  box-shadow: 0 0 .75em 1px var(--color-secondary-a02);
}

.hm-button[data-variant="tertiary"]:active, nav.hm__nav h1[data-variant="tertiary"]:active, .hm-button.--tertiary:active, nav.hm__nav h1.--tertiary:active, button[data-variant="tertiary"]:active, button.--tertiary:active, a[data-variant="tertiary"]:active, a.--tertiary:active {
  color: var(--color-primary-lm6);
  background-color: var(--color-secondary-lp2);
  box-shadow: 0 0 1.5em 1px var(--color-secondary-a04) !important;
}

.hm-button[data-variant="outline"], nav.hm__nav a[data-variant="outline"], nav.hm__nav h1[data-variant="outline"], nav.hm__nav [data-variant="outline"].hm-button, .hm-button.--outline, nav.hm__nav a.--outline, nav.hm__nav h1.--outline, nav.hm__nav .--outline.hm-button, button[data-variant="outline"], button.--outline, button.toggle__l00k__nav, a[data-variant="outline"], a.--outline {
  color: var(--color-foreground);
  border-color: var(--color-foreground);
  border-width: var(--border-width);
  background-color: #0000;
}

.hm-button[data-variant="outline"]:hover, nav.hm__nav h1[data-variant="outline"]:hover, .hm-button[data-variant="outline"]:focus, nav.hm__nav h1[data-variant="outline"]:focus, .hm-button.--outline:hover, nav.hm__nav h1.--outline:hover, .hm-button.--outline:focus, nav.hm__nav h1.--outline:focus, button[data-variant="outline"]:hover, button[data-variant="outline"]:focus, button.--outline:hover, button.toggle__l00k__nav:hover, button.--outline:focus, button.toggle__l00k__nav:focus, a[data-variant="outline"]:hover, a[data-variant="outline"]:focus, a.--outline:hover, a.--outline:focus {
  color: var(--color-background);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.hm-button[data-variant="outline"][data-variant="inverted"], nav.hm__nav h1[data-variant="outline"][data-variant="inverted"], .hm-button[data-variant="outline"].--inverted, nav.hm__nav h1[data-variant="outline"].--inverted, .hm-button.--outline[data-variant="inverted"], nav.hm__nav h1.--outline[data-variant="inverted"], .hm-button.--outline.--inverted, nav.hm__nav h1.--outline.--inverted, button[data-variant="outline"][data-variant="inverted"], button[data-variant="outline"].--inverted, button.--outline[data-variant="inverted"], button[data-variant="inverted"].toggle__l00k__nav, button.--outline.--inverted, button.--inverted.toggle__l00k__nav, a[data-variant="outline"][data-variant="inverted"], a[data-variant="outline"].--inverted, a.--outline[data-variant="inverted"], a.--outline.--inverted {
  color: var(--color-foreground);
  border-color: var(--color-foreground);
  background-color: #0000;
}

.hm-button[data-variant="outline"][data-variant="inverted"]:hover, nav.hm__nav h1[data-variant="outline"][data-variant="inverted"]:hover, .hm-button[data-variant="outline"][data-variant="inverted"]:focus, nav.hm__nav h1[data-variant="outline"][data-variant="inverted"]:focus, .hm-button[data-variant="outline"].--inverted:hover, nav.hm__nav h1[data-variant="outline"].--inverted:hover, .hm-button[data-variant="outline"].--inverted:focus, nav.hm__nav h1[data-variant="outline"].--inverted:focus, .hm-button.--outline[data-variant="inverted"]:hover, nav.hm__nav h1.--outline[data-variant="inverted"]:hover, .hm-button.--outline[data-variant="inverted"]:focus, nav.hm__nav h1.--outline[data-variant="inverted"]:focus, .hm-button.--outline.--inverted:hover, nav.hm__nav h1.--outline.--inverted:hover, .hm-button.--outline.--inverted:focus, nav.hm__nav h1.--outline.--inverted:focus, button[data-variant="outline"][data-variant="inverted"]:hover, button[data-variant="outline"][data-variant="inverted"]:focus, button[data-variant="outline"].--inverted:hover, button[data-variant="outline"].--inverted:focus, button.--outline[data-variant="inverted"]:hover, button[data-variant="inverted"].toggle__l00k__nav:hover, button.--outline[data-variant="inverted"]:focus, button[data-variant="inverted"].toggle__l00k__nav:focus, button.--outline.--inverted:hover, button.--inverted.toggle__l00k__nav:hover, button.--outline.--inverted:focus, button.--inverted.toggle__l00k__nav:focus, a[data-variant="outline"][data-variant="inverted"]:hover, a[data-variant="outline"][data-variant="inverted"]:focus, a[data-variant="outline"].--inverted:hover, a[data-variant="outline"].--inverted:focus, a.--outline[data-variant="inverted"]:hover, a.--outline[data-variant="inverted"]:focus, a.--outline.--inverted:hover, a.--outline.--inverted:focus {
  color: var(--color-background);
  background-color: var(--color-foreground);
}

.hm-button[data-variant="ghost"], nav.hm__nav a[data-variant="ghost"], nav.hm__nav h1[data-variant="ghost"], nav.hm__nav [data-variant="ghost"].hm-button, .hm-button.--ghost, nav.hm__nav a.--ghost, nav.hm__nav h1.--ghost, nav.hm__nav .--ghost.hm-button, button[data-variant="ghost"], button.--ghost, a[data-variant="ghost"], a.--ghost {
  color: var(--color-primary);
  background-color: #0000;
  border-color: #0000 !important;
}

.hm-button[data-variant="ghost"]:hover, nav.hm__nav h1[data-variant="ghost"]:hover, .hm-button[data-variant="ghost"]:focus, nav.hm__nav h1[data-variant="ghost"]:focus, .hm-button.--ghost:hover, nav.hm__nav h1.--ghost:hover, .hm-button.--ghost:focus, nav.hm__nav h1.--ghost:focus, button[data-variant="ghost"]:hover, button[data-variant="ghost"]:focus, button.--ghost:hover, button.--ghost:focus, a[data-variant="ghost"]:hover, a[data-variant="ghost"]:focus, a.--ghost:hover, a.--ghost:focus {
  color: var(--color-foreground-secondary) !important;
  background-color: var(--color-background-secondary) !important;
}

.hm-button.--wide, nav.hm__nav a.--wide, nav.hm__nav h1.--wide, nav.hm__nav .--wide.hm-button, button.--wide, a.--wide {
  text-align: center;
  width: 100%;
}

.hm-button.--mw, nav.hm__nav a.--mw, nav.hm__nav h1.--mw, nav.hm__nav .--mw.hm-button, button.--mw, a.--mw {
  width: clamp(13em, 85%, 30em);
  margin: 0 auto;
}

.hm-button.--flex, nav.hm__nav a.--flex, nav.hm__nav h1.--flex, nav.hm__nav .--flex.hm-button, button.--flex, a.--flex {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.hm-button[data-round], nav.hm__nav a[data-round], nav.hm__nav h1[data-round], nav.hm__nav [data-round].hm-button, .hm-button.--round, nav.hm__nav a.--round, nav.hm__nav h1.--round, nav.hm__nav .--round.hm-button, button[data-round], button.--round, button.toggle__l00k__nav, a[data-round], a.--round {
  border-radius: 1111em;
  padding-left: 1.375em;
  padding-right: 1.375em;
}

.hm-button[disabled], nav.hm__nav a[disabled], nav.hm__nav h1[disabled], nav.hm__nav [disabled].hm-button, button[disabled], a[disabled] {
  cursor: not-allowed;
  color: var(--color-foreground--l60) !important;
  box-shadow: none !important;
  background-color: #0000 !important;
}

.hm-button[disabled]:hover, nav.hm__nav h1[disabled]:hover, .hm-button[disabled]:active, nav.hm__nav h1[disabled]:active, .hm-button[disabled]:focus, nav.hm__nav h1[disabled]:focus, button[disabled]:hover, button[disabled]:active, button[disabled]:focus, a[disabled]:hover, a[disabled]:active, a[disabled]:focus {
  transform: none;
  color: var(--color-foreground--l90) !important;
}

.hm-button--icon-only, button.toggle__l00k__nav {
  text-align: center;
  padding: .25em;
}

.hm-button--icon-only abbr, button.toggle__l00k__nav abbr {
  line-height: auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.hm-button--icon-only abbr svg, button.toggle__l00k__nav abbr svg {
  width: 100%;
}

.hm-button--icon-only[data-size="sm"], button[data-size="sm"].toggle__l00k__nav {
  width: 2.25em;
  height: 2.25em;
  padding: .375em;
}

.hm-button--icon-only[data-size="md"], button[data-size="md"].toggle__l00k__nav {
  width: 3.5em;
  height: 3.5em;
  padding: .75em;
}

.hm-button--icon-only[data-size="lg"], button[data-size="lg"].toggle__l00k__nav {
  width: 4em;
  height: 4em;
  padding: .75em;
}

.hm-button--icon-only.--xl, button.toggle__l00k__nav, .hm-button--icon-only[data-size="xl"] {
  width: 4em;
  height: 4em;
  padding: 1em;
}

.hm-button--icon-only[data-round], button[data-round].toggle__l00k__nav {
  text-align: center;
}

:root {
  --button-active-color: var(--color-foreground);
}

button.hm-button__switch {
  background-color: var(--color-background);
  border: 1px solid var(--color-foreground);
  white-space: nowrap;
  transition: background-color var(--animation-step) ease-in-out, border-color var(--animation-step) ease-in-out;
  backdrop-filter: blur(.25em);
  border-radius: 10em;
  padding: .25em;
  display: block;
}

button.hm-button__switch span {
  letter-spacing: .05em;
  pointer-events: none;
  width: 3em;
  font-size: .875em;
  line-height: var(--font-line-height-body);
  text-transform: uppercase;
  z-index: 1;
  transition: color calc(var(--animation-step) * 1.5) ease-in-out;
  border-radius: 10em;
  padding: .25em;
  display: inline-block;
  position: relative;
  background-color: #0000 !important;
}

button.hm-button__switch span:before {
  content: "";
  background-color: color-mix(in srgb, var(--color-foreground) 20%, transparent 95%);
  z-index: -2;
  width: 100%;
  height: 100%;
  transition: transform calc(var(--animation-step) * 2) cubic-bezier(0, -.25, .5, 1.25), background-color var(--animation-step) ease-in-out;
  border-radius: 111em;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

button.hm-button__switch span abbr {
  transition: opacity calc(var(--animation-step) / 2) ease-in-out 0;
  opacity: 0;
  margin: .375em 0 0 .2675em;
  position: absolute;
  left: -100%;
}

button.hm-button__switch span abbr svg {
  width: 175%;
  height: auto;
  overflow: visible;
}

button.hm-button__switch span abbr svg path {
  fill: none;
  stroke-width: 1.75px;
  stroke: var(--color-background);
  transition: stroke-dashoffset calc(var(--animation-step) * 1.5) ease calc(var(--animation-step) * 1.25);
  stroke-dasharray: 20;
  stroke-dashoffset: 20px;
}

button.hm-button__switch:active {
  transform: none;
}

button.hm-button__switch[role="switch"][aria-checked="false"] :first-child, button.hm-button__switch[role="switch"][aria-checked="true"] :first-child {
  color: inherit;
  z-index: 2;
  background-color: #0000 !important;
}

button.hm-button__switch[role="switch"][aria-checked="false"] :first-child:before, button.hm-button__switch[role="switch"][aria-checked="true"] :first-child:before {
  display: none;
}

button.hm-button__switch[role="switch"][aria-checked="true"] :last-child:before {
  background-color: var(--color-foreground);
  border-color: var(--color-foreground);
  box-shadow: 0 0 .5em color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
}

button.hm-button__switch[role="switch"][aria-checked="true"] :last-child abbr {
  opacity: 1;
  transition: opacity calc(var(--animation-step) / 2) ease-in-out calc(var(--animation-step) * 1.25);
}

button.hm-button__switch[role="switch"][aria-checked="true"] :last-child abbr svg {
  width: 100%;
  transform: scale(1.35)translate(.41em, -.05em);
}

button.hm-button__switch[role="switch"][aria-checked="true"] :last-child abbr svg path {
  stroke-dasharray: 20;
  stroke-dashoffset: 0;
  stroke-width: 2px;
  stroke-linecap: round;
}

button.hm-button__switch[role="switch"][aria-checked="false"] :last-child {
  color: color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
}

button.hm-button__switch[role="switch"][aria-checked="false"] :last-child:before {
  transform: translateX(calc(-100% - .275em));
}

button.hm-button__switch[role="switch"][aria-checked="true"] :first-child {
  color: color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
}

button.hm-button__switch[role="switch"][aria-checked="true"] :last-child {
  color: var(--color-background);
}

button.hm-button__switch:focus {
  outline: .25em solid color-mix(in srgb, var(--color-primary) 50%, transparent 95%);
  outline-offset: .125em;
  box-shadow: var(--focus-box-shadow);
}

button.hm-button__switch:focus:not(:focus-visible) {
  box-shadow: none;
  outline: none;
}

button.hm-button__switch:focus:not(:-moz-focusring) {
  box-shadow: none;
  outline: none;
}

label.hm-label__switch {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  opacity: .6;
  padding: 0 .5em 0 0;
  font-weight: 300;
}

label.hm-label__switch:hover {
  opacity: 1;
}

button.hm-button__switch.switch--sm {
  background-color: color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
  border: 1px solid color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
  font-size: .75em;
}

button.hm-button__switch.switch--sm span {
  width: 2.5em;
  height: 2.5em;
  color: #0000 !important;
}

button.hm-button__switch.switch--sm span:before {
  background-color: color-mix(in srgb, var(--color-background) 100%, transparent 0%);
  transition: transform calc(var(--animation-step) * 1.25) cubic-bezier(0, -.25, .5, 1), background-color var(--animation-step) ease-in-out;
}

button.hm-button__switch.switch--sm[role="switch"][aria-checked="false"] :last-child:before {
  transform: translateX(calc(-100% - .25em));
  background-color: var(--color-background) !important;
}

button.hm-button__switch.switch--sm[role="switch"][aria-checked="true"] {
  background-color: var(--button-active-color);
  border-color: var(--button-active-color);
}

button.hm-button__switch.switch--sm[role="switch"][aria-checked="true"] span:before {
  background-color: var(--color-background);
  border-color: var(--color-background);
}

button.hm-button__switch.switch--xs {
  background-color: color-mix(in srgb, var(--color-foreground) 40%, transparent 95%);
  border: 0px solid color-mix(in srgb, var(--color-foreground) 50%, transparent 95%);
  padding: .5em;
  font-size: .5em;
}

button.hm-button__switch.switch--xs span {
  width: 2.5em;
  height: 2.5em;
  color: #0000 !important;
}

button.hm-button__switch.switch--xs span:before {
  transition: transform calc(var(--animation-step) * 1.25) cubic-bezier(0, -.25, .5, 1), background-color var(--animation-step) ease-in-out;
}

button.hm-button__switch.switch--xs[role="switch"][aria-checked="false"] :last-child:before {
  transform: translateX(calc(-100% - .25em));
  background-color: var(--color-background) !important;
}

button.hm-button__switch.switch--xs[role="switch"][aria-checked="true"] {
  background-color: var(--button-active-color);
  border-color: var(--button-active-color);
}

button.hm-button__switch.switch--xs[role="switch"][aria-checked="true"] span:before {
  background-color: var(--color-background);
  border-color: var(--color-background);
}

button.menuButtonExample {
  padding: .25em;
}

button.menuButtonExample:active {
  transform: none;
}

button.menuButtonExample abbr {
  pointer-events: none;
  width: 1.5em;
  height: 1.5em;
  margin: 0;
  position: relative;
}

button.menuButtonExample abbr:before, button.menuButtonExample abbr:after {
  content: "";
  background-color: var(--color-foreground);
  width: 100%;
  height: 2px;
  transition: transform calc(var(--animation-step) * 2) ease-in-out;
  display: block;
  position: absolute;
  top: calc(50% - .25em);
  left: 0;
}

button.menuButtonExample abbr:after {
  transition: transform calc(var(--animation-step) * 2) ease-in-out;
  top: calc(50% + .25em);
}

button.menuButtonExample[aria-expanded="true"] abbr:before {
  transform: translateY(.25em)rotate(-225deg);
}

button.menuButtonExample[aria-expanded="true"] abbr:after {
  transform: translateY(-.25em)rotate(225deg);
}

button.menuButtonExample[aria-expanded="false"] abbr:before, button.menuButtonExample[aria-expanded="false"] abbr:after {
  transform: translateY(0)rotate(0);
}

button.toggle__l00k__nav abbr.closeIcon {
  pointer-events: none;
  width: 1.5em;
  height: 1.5em;
  margin: 0;
  position: absolute;
}

button.toggle__l00k__nav abbr.closeIcon:before, button.toggle__l00k__nav abbr.closeIcon:after {
  content: "";
  background-color: var(--color-foreground);
  width: 100%;
  height: 2px;
  transition: transform calc(var(--animation-step) * 2) ease-in-out;
  display: block;
  position: absolute;
  top: calc(50% - .25em);
  left: 0;
  transform: translateY(.25em)rotate(-225deg)scaleX(0);
}

button.toggle__l00k__nav abbr.closeIcon:after {
  transition: transform calc(var(--animation-step) * 2) ease-in-out;
  top: calc(50% + .25em);
  transform: translateY(-.25em)rotate(225deg)scaleX(0);
}

button.toggle__l00k__nav[aria-expanded="true"] span {
  opacity: 0;
}

button.toggle__l00k__nav[aria-expanded="false"] span {
  opacity: 1;
}

button.toggle__l00k__nav[aria-expanded="false"] abbr.closeIcon:before {
  transform: translateY(.25em)rotate(-225deg)scaleX(0);
}

button.toggle__l00k__nav[aria-expanded="false"] abbr.closeIcon:after {
  transform: translateY(-.25em)rotate(225deg)scaleX(0);
}

.hm-label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  font-weight: 500;
  display: inline-block;
}

.hm-text-input {
  font-weight: 400;
  line-height: var(--font-line-height-body);
  box-sizing: border-box;
  border: 1px solid windowframe;
  outline: 0;
  font-size: 1em;
}

.hm-text-input:focus {
  border-color: var(--color-primary);
  outline: .125em solid var(--color-primary);
  outline-offset: .125em;
  box-shadow: var(--focus-box-shadow);
  border-radius: .25em;
}

.hm-input-wrapper--default-theme:focus-within label {
  color: var(--color-primary);
  opacity: 1 !important;
}

.hm-input-wrapper--default-theme input {
  width: 100%;
}

.hm-label--default-theme {
  padding: 0 0 .375em;
}

.hm-text-input--default-theme {
  height: 3.5em;
  transition: outline var(--animation-step) ease-in-out, transform var(--animation-step) ease-in-out, box-shadow calc(var(--animation-step) * 2) ease-in-out, border-color var(--animation-step) ease-in-out, background-color var(--animation-step) ease-in-out;
  border-radius: .25rem;
  padding: 1.125rem;
  line-height: 1.125em;
  box-shadow: 0 0 0 2em #0000;
}

.hm-text-input--default-theme::placeholder {
  color: var(--color-foreground);
  font-weight: 300;
}

.hm-text-input--default-theme:focus {
  outline: .25em solid var(--color-primary);
  outline-offset: .0675em;
  box-shadow: var(--focus-box-shadow);
  border-color: #0000;
}

.hm-text-input--default-theme[data-size="sm"], .hm-text-input--default-theme.--sm, nav.hm__nav a.hm-text-input--default-theme, nav.hm__nav h1.hm-text-input--default-theme, nav.hm__nav .hm-text-input--default-theme.hm-button, nav.hm__nav button.hm-text-input--default-theme.toggle__l00k__nav {
  height: 2.25em;
  padding: .375rem .75rem;
}

.hm-input-wrapper--inner-label {
  position: relative;
}

.hm-input-wrapper--inner-label .hm-label {
  opacity: .875;
  pointer-events: none;
  z-index: 2;
  transform-origin: 0 0;
  transition: transform calc(var(--animation-step) / 2) ease-in-out, opacity calc(var(--animation-step) * 2) ease-in-out;
  font-size: .975em;
  font-weight: 400;
  position: absolute;
  top: 1rem;
  left: 1.1375rem;
}

.hm-input-wrapper--inner-label .hm-text-input {
  z-index: 1;
  height: 4.5em;
  padding: 2.25rem 1.125rem .875rem;
  position: relative;
}

.hm-input-wrapper--inner-label .hm-text-input::placeholder {
  opacity: .4;
  color: var(--color-foreground);
}

.hm-input-wrapper--inner-label .hm-text-input:focus::placeholder {
  opacity: .25;
}

.hm-input-wrapper--inner-label.hm-input--filled .hm-label {
  opacity: .875 !important;
}

.hm-input-wrapper--inner-label:focus-within label {
  color: var(--color-primary);
  opacity: 1 !important;
}

.hm-input-wrapper.--error label, .hm-input-wrapper.--error .hm-form-helper {
  color: var(--color-red);
}

.hm-input-wrapper.--error input, .hm-input-wrapper.--error textarea {
  border-color: var(--color-red);
  color: var(--color-red-lm2);
}

.hm-input-wrapper.--error:focus-within label {
  color: var(--color-red);
  opacity: 1 !important;
}

.hm-text-input__invalid-icon {
  fill: var(--color-red);
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.hm-form-helper {
  margin-top: .25em;
  font-size: .875em;
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: unset;
  line-height: var(--font-line-height-body);
  margin: 0 0 1em;
  font-weight: 800;
}

p {
  font-weight: 300;
  line-height: var(--font-line-height-body);
  margin: 0 0 1em;
}

h1[data-heading="xxl" i], h1 .--xxl, h2[data-heading="xxl" i], h2 .--xxl, h3[data-heading="xxl" i], h3 .--xxl, h4[data-heading="xxl" i], h4 .--xxl, h5[data-heading="xxl" i], h5 .--xxl, h6[data-heading="xxl" i], h6 .--xxl, p[data-heading="xxl" i], p .--xxl, ul[data-heading="xxl" i], ul .--xxl, span[data-heading="xxl" i], span .--xxl {
  letter-spacing: -.0375em;
  margin-bottom: .375em;
  font-size: clamp(1.75em, 8vw, 8em);
  line-height: 1.05em;
}

h1[data-heading="xl" i], h1.--xl, h2[data-heading="xl" i], h2.--xl, h3[data-heading="xl" i], h3.--xl, h4[data-heading="xl" i], h4.--xl, h5[data-heading="xl" i], h5.--xl, h6[data-heading="xl" i], h6.--xl, p[data-heading="xl" i], p.--xl, ul[data-heading="xl" i], ul.--xl, span[data-heading="xl" i], span.--xl {
  letter-spacing: -.01em;
  margin: 0 0 .5em;
  font-size: clamp(2em, 6.5vw, 5em);
  line-height: 1.125em;
}

h1[data-heading="lg" i], h1.--lg, h2[data-heading="lg" i], h2.--lg, h3[data-heading="lg" i], h3.--lg, h4[data-heading="lg" i], h4.--lg, h5[data-heading="lg" i], h5.--lg, h6[data-heading="lg" i], h6.--lg, p[data-heading="lg" i], p.--lg, ul[data-heading="lg" i], ul.--lg, span[data-heading="lg" i], span.--lg {
  font-size: clamp(2em, 3vw, 3em);
  line-height: var(--font-line-height-heading);
  letter-spacing: 0;
  margin: 0 0 1em;
}

h1[data-heading="md" i], h1.--md, h2[data-heading="md" i], h2.--md, h3[data-heading="md" i], h3.--md, h4[data-heading="md" i], h4.--md, h5[data-heading="md" i], h5.--md, h6[data-heading="md" i], h6.--md, p[data-heading="md" i], p.--md, ul[data-heading="md" i], ul.--md, span[data-heading="md" i], span.--md {
  font-size: 1.25em;
  line-height: var(--font-line-height-body);
  margin: 0 0 1em;
}

h1[data-heading="sm" i], h1.--sm, nav.hm__nav h1, nav.hm__nav h1.hm-button, h2[data-heading="sm" i], h2.--sm, nav.hm__nav h2.hm-button, h3[data-heading="sm" i], h3.--sm, nav.hm__nav h3.hm-button, h4[data-heading="sm" i], h4.--sm, nav.hm__nav h4.hm-button, h5[data-heading="sm" i], h5.--sm, nav.hm__nav h5.hm-button, h6[data-heading="sm" i], h6.--sm, nav.hm__nav h6.hm-button, p[data-heading="sm" i], p.--sm, nav.hm__nav p.hm-button, ul[data-heading="sm" i], ul.--sm, nav.hm__nav ul.hm-button, span[data-heading="sm" i], span.--sm, nav.hm__nav span.hm-button {
  font-size: 1em;
  line-height: var(--font-line-height-body);
  margin: 0 0 1em;
}

h1[data-heading="xs" i], h1.--xs, h2[data-heading="xs" i], h2.--xs, h3[data-heading="xs" i], h3.--xs, h4[data-heading="xs" i], h4.--xs, h5[data-heading="xs" i], h5.--xs, h6[data-heading="xs" i], h6.--xs, p[data-heading="xs" i], p.--xs, ul[data-heading="xs" i], ul.--xs, span[data-heading="xs" i], span.--xs {
  font-size: .875em;
  line-height: var(--font-line-height-body);
  letter-spacing: .01em;
  margin: 0 0 1em;
  font-weight: 600;
}

p {
  max-width: 38em;
}

.--inverted {
  color: var(--color-background);
  background: var(--color-primary) !important;
}

.--inverted .muted, .--inverted .lightText {
  color: var(--color-background);
  opacity: .6;
}

.--inverted h1[size="xs" i], .--inverted h2[size="xs" i], .--inverted h3[size="xs" i], .--inverted h4[size="xs" i], .--inverted h5[size="xs" i], .--inverted h6[size="xs" i], .--inverted p[size="xs" i], .--inverted div[size="xs" i], .--inverted span[size="xs" i] {
  letter-spacing: .03em;
  font-weight: 400;
}

.--inverted p {
  letter-spacing: .01em;
  font-weight: 300;
}

a {
  color: var(--color-primary);
  transition: transform calc(var(--animation-step) / 2) ease-in-out;
}

a:hover {
  color: var(--color-primary-lm2);
}

@media (prefers-color-scheme: dark) {
  path.favIcon {
    fill: #fff;
  }
}

main, header, .l00k__user-heading {
  margin: 0 auto;
}

nav.hm__nav {
  animation-duration: var(--animation-step);
  padding: var(--space) var(--space) 0;
  font-family: var(--font-mono);
  text-transform: uppercase;
  grid-template-columns: repeat(auto-fit, minmax(6.5em, .25fr));
  justify-content: space-between;
  align-items: center;
  gap: .25em;
  margin: 1em auto;
  display: grid;
}

nav.hm__nav h1, nav.hm__nav h2, nav.hm__nav h3 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 3.5em;
  font-weight: 100;
}

nav.hm__nav h1 {
  background-color: var(--color-foreground);
  color: var(--color-background);
  letter-spacing: .125em;
  text-transform: uppercase;
  font-size: 1rem;
}

nav.hm__nav a, nav.hm__nav h1, nav.hm__nav .hm-button, nav.hm__nav button.toggle__l00k__nav {
  background-color: var(--color-foreground);
  color: var(--color-background);
  letter-spacing: .125em;
  text-transform: uppercase;
  border-width: var(--border-width);
  font-weight: 100;
}

nav.hm__nav .hm__user-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

nav.hm__nav form {
  padding: 0;
}

nav.hm__nav form button {
  text-transform: uppercase;
  letter-spacing: .125em;
  width: 100%;
  margin: 0;
}

video::-webkit-media-controls {
  opacity: 0;
  display: none !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

html {
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

main {
  --right: 0;
  --color-user-background: #000002;
  grid-template-columns: auto var(--right);
  width: 100%;
  transition: grid-template-columns calc(var(--animation-step) * 1.5) cubic-bezier(0, .25, .25, 1.2) 0s, opacity calc(var(--animation-step) * 1) ease 2s;
  display: grid;
  position: fixed;
  overflow: hidden;
}

@media only screen and (width <= 699px) {
  main {
    grid-template-columns: 100% var(--right);
  }
}

main .l00k__nav {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden scroll;
}

main .l00k__nav > * {
  transition: opacity calc(var(--animation-step) * 1.25) ease 0s;
  opacity: 0;
  width: 100%;
}

@media only screen and (width <= 699px) {
  main .l00k__nav {
    width: 300px;
    min-height: 100dvh;
    transition: transform .3s ease-in-out;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -340px;
    border-left: 1px solid #0000 !important;
  }
}

main.w__l00k__nav {
  --right: 19em;
  will-change: grid-template-columns, opacity;
  height: 100dvh;
  transition: grid-template-columns calc(var(--animation-step) * 3) cubic-bezier(0, .25, .25, 1.2) 0s, opacity calc(var(--animation-step) * 1) ease 3s;
}

@media only screen and (width >= 1400px) {
  main.w__l00k__nav {
    --right: 26em;
  }
}

@media only screen and (width <= 699px) {
  main.w__l00k__nav .l00k__nav {
    z-index: 11111;
    width: 100%;
    position: absolute;
    left: 0;
  }
}

main.w__l00k__nav .l00k__nav > * {
  transition: opacity calc(var(--animation-step) * 1) ease .2875s;
  opacity: 1;
}

main.w__l00k__nav .calendar__heading {
  display: none;
}

@media only screen and (width <= 699px) {
  main.w__stacked {
    height: inherit;
    grid-template-rows: 1fr min-content;
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }

  main.w__stacked article {
    min-height: 92dvh;
  }

  main.w__stacked .l00k__nav {
    z-index: 11;
    width: 100%;
    min-height: 50dvh;
    position: relative;
    left: 0;
  }
}

main.w__stacked .l00k__nav > * {
  transition: opacity calc(var(--animation-step) * 1) ease .2875s;
  opacity: 1;
}

main.wo__l00k__nav {
  grid-template-columns: 1fr;
  height: 100dvh;
}

@media only screen and (width <= 699px) {
  main.wo__l00k__nav {
    height: inherit;
    grid-template-rows: min-content;
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }

  main.wo__l00k__nav article {
    min-height: 92dvh;
  }
}

main.wo__l00k__nav .l00k__nav {
  border: none;
}

@media only screen and (width <= 699px) {
  main.wo__l00k__nav .l00k__nav {
    z-index: 11;
    width: 100%;
    min-height: 50dvh;
    position: relative;
    left: 0;
  }
}

main.wo__l00k__nav .l00k__nav > * {
  transition: opacity calc(var(--animation-step) * 1) ease .2875s;
  opacity: 1;
}

@media only screen and (width <= 699px) {
  main.w__scroll {
    grid-template-rows: min-content min-content;
    grid-template-columns: 1fr;
    height: 100dvh;
    overflow-y: hidden;
  }

  main.w__scroll article {
    min-height: unset;
    height: 100dvh;
    overflow-y: scroll;
  }

  main.w__scroll .l00k__nav {
    z-index: 11;
    width: 100%;
    min-height: 50dvh;
    position: relative;
    left: 0;
  }
}

main.w__scroll .l00k__nav > * {
  transition: opacity calc(var(--animation-step) * 1) ease .2875s;
  opacity: 1;
}

main.l00k header.l00k__navigation {
  z-index: 111111;
  width: 100%;
  position: absolute;
}

button.toggle__l00k__nav {
  margin-left: calc(-1 * var(--space) / 2);
  margin-top: calc(-1 * var(--space) / 2);
  color: var(--color-foreground);
  z-index: 111;
  right: calc(var(--space) * .375);
  top: calc(var(--space) * .875);
  transition: background-color .5s, transform .125s cubic-bezier(.075, .82, .165, 1) .125s;
  position: absolute;
  overflow: hidden;
  border-color: color-mix(in srgb, var(--color-user-background) 40%, var(--color-foreground) 95%) !important;
  background-color: #fff !important;
}

@media only print {
  button.toggle__l00k__nav {
    display: none;
  }
}

button.toggle__l00k__nav span {
  color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 90%);
  text-align: center;
  transform-origin: center;
  font-weight: 800;
  transform: scale(2.5)translate(.01em, -.03675em);
}

button.toggle__l00k__nav span abbr.elips {
  background-color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 90%);
  border-radius: 111em;
  width: .25rem;
  height: .25em;
  margin-top: .0675em;
  font-size: 16px;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1), opacity .125s .25s;
  display: block;
  position: relative;
  transform: scale(1);
}

button.toggle__l00k__nav span abbr.elips:after, button.toggle__l00k__nav span abbr.elips:before {
  content: "";
  transform-origin: center;
  background-color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 90%);
  border-radius: 111em;
  width: .25em;
  height: .25em;
  transition: transform .125s cubic-bezier(.075, .82, .165, 1) .2875s, opacity .125s;
  display: block;
  position: absolute;
  left: -.375em;
  transform: scale(1);
}

button.toggle__l00k__nav span abbr.elips:after {
  left: unset;
  right: -.375em;
}

button.toggle__l00k__nav .profile__img {
  transition: opacity calc(var(--animation-step) * 3) ease .5s, transform var(--animation-step) ease 0s;
  pointer-events: none;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

button.toggle__l00k__nav abbr.closeIcon:after, button.toggle__l00k__nav abbr.closeIcon:before {
  opacity: 0;
  background-color: color-mix(in srgb, var(--color-user-background) 50%, var(--color-foreground) 100%);
  transition: transform .25s cubic-bezier(.075, .82, .165, 1) .125s, opacity .125s;
}

button.toggle__l00k__nav[aria-expanded="true"] {
  transform: scale(.875)translate(.125em, -.25em);
}

@media only screen and (width <= 699px) {
  button.toggle__l00k__nav[aria-expanded="true"] {
    top: -.375em;
    left: -.375em;
    transform: scale(.5);
  }

  button.toggle__l00k__nav[aria-expanded="true"] abbr.closeIcon:after, button.toggle__l00k__nav[aria-expanded="true"] abbr.closeIcon:before {
    height: 3px;
  }
}

button.toggle__l00k__nav[aria-expanded="true"] .profile__img {
  opacity: 0;
  transition: transform .25s cubic-bezier(.075, .82, .165, 1) .5s, opacity .25s .375s;
}

button.toggle__l00k__nav[aria-expanded="true"] abbr.elips {
  opacity: 0;
  transition: transform .25s cubic-bezier(.075, .82, .165, 1) .25s, opacity .25s .25s;
  transform: scale(0);
}

button.toggle__l00k__nav[aria-expanded="true"] abbr.elips:after, button.toggle__l00k__nav[aria-expanded="true"] abbr.elips:before {
  transition: transform .5s cubic-bezier(.075, .82, .165, 1) .25s, opacity .5s .5s;
  transform: scale(0);
}

button.toggle__l00k__nav[aria-expanded="true"] abbr.closeIcon:after, button.toggle__l00k__nav[aria-expanded="true"] abbr.closeIcon:before {
  opacity: 1;
  transition: transform .125s cubic-bezier(.075, .82, .165, 1), opacity .125s .25s;
}

button.toggle__l00k__nav[aria-expanded="true"] abbr.closeIcon:before {
  transform: translateY(.25em)rotate(-45deg);
}

button.toggle__l00k__nav[aria-expanded="true"] abbr.closeIcon:after {
  transform: translateY(-.25em)rotate(45deg);
}

button.toggle__l00k__nav:hover .profile__img {
  transition: transform var(--animation-step) cubic-bezier(.79, -.48, .25, 1) 0s;
  transform: scale(1.0375);
}

button.toggle__l00k__nav:hover abbr.closeIcon:before {
  transform: translateY(.25em)rotate(-225deg)scaleX(.8);
}

button.toggle__l00k__nav:hover abbr.closeIcon:after {
  transform: translateY(-.25em)rotate(225deg)scaleX(.8);
}

button.toggle__l00k__nav:hover span abbr {
  transform: rotate(-25deg);
}

main .l00k__nav {
  background-color: var(--color-background);
  border-left: 1px solid color-mix(in srgb, var(--color-user-background) 70%, var(--color-foreground) 95%);
  align-items: start;
  display: grid;
}

main .l00k__nav > div {
  padding: var(--space);
  text-align: center;
  align-content: start;
  justify-items: center;
  gap: calc(var(--space) * 2);
  grid-template-columns: 1fr;
  display: grid;
}

main .l00k__nav .l00k__author {
  background-color: var(--color-user-background);
  background-color: color-mix(in srgb, var(--color-user-background) 100%, var(--color-foreground) 15%);
  border: 1px color-mix(in hsl, var(--color-user-background), white 0%) solid;
  image-rendering: pixelated;
  background-position: center;
  background-size: cover;
  border-radius: .125em;
  align-content: center;
  align-self: flex-start;
  min-width: 10em;
  max-width: 90vmin;
  margin: 0 auto 1em;
  display: grid;
  overflow: hidden;
}

main .l00k__nav .l00k__author span {
  text-align: center;
  transform-origin: center;
  font-weight: 800;
  transform: scale(5)translate(.01em, -.03675em);
}

main .l00k__nav .l00k__author svg {
  width: 100%;
  height: auto;
}

main .l00k__nav h1 {
  text-transform: uppercase;
  letter-spacing: .0675em;
  filter: url("#goo3");
  color: var(--color-foreground);
  font-size: 1.875em;
  font-family: var(--font-mono);
  text-align: center;
  align-self: flex-start;
  margin-bottom: auto;
  padding-left: .5em;
  padding-right: .5em;
}

main .l00k__nav h1::selection {
  background: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 80%) !important;
}

main .l00k__nav h1::selection {
  background: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 80%) !important;
}

main .l00k__nav .l00k__brand {
  font-family: var(--font-mono);
  letter-spacing: .125em;
  margin: 0 auto var(--space);
  text-align: justify;
  opacity: .5;
  border-radius: .25em;
  align-self: end;
  width: auto;
  padding: .25em .4375em;
  font-size: .75em;
  font-weight: 300;
  line-height: 1;
}

main .l00k__nav ul {
  margin: 0 auto calc(var(--space) * 2);
  padding: 0 var(--space);
  justify-self: start;
  width: 100%;
  max-width: 700px;
}

main .l00k__nav ul li {
  margin: 0 0 calc(var(--space) / 2.25);
  padding: 0;
}

main .l00k__nav ul li a {
  letter-spacing: .25em;
  border-radius: calc(var(--border-radius) / 2);
  background-color: var(--color-white);
  color: color-mix(in srgb, var(--color-user-background) 100%, var(--color-foreground) 95%);
  box-shadow: 0 0 0em 1px color-mix(in srgb, var(--color-user-background) 0%, transparent 100%);
  transition: transform calc(var(--animation-step) * 2) cubic-bezier(0, -.5, .5, 4) var(--animation-step), border-radius calc(var(--animation-step) * 2) cubic-bezier(0, -.75, .5, 2) var(--animation-step), background-color calc(var(--animation-step) * 1) ease var(--animation-step), color calc(var(--animation-step) * 2) ease var(--animation-step), box-shadow calc(var(--animation-step) * 2) ease 0s;
  background-color: #0000;
  grid-template-columns: min-content 1fr min-content;
  gap: .5em;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.375;
  display: grid;
}

main .l00k__nav ul li a abbr {
  transition: transform calc(var(--animation-step) * 2) ease;
  opacity: 1;
  background-color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 80%);
  border-radius: calc(var(--border-radius) / 1);
  width: .25em;
  height: .25em;
  margin: 0 calc(var(--sapce) * 2) 0 var(--sapce);
  transform: translateX(-1.25em);
}

main .l00k__nav ul li a abbr:nth-of-type(2) {
  transform: translateX(1.25em);
}

main .l00k__nav ul li a:hover {
  background-color: var(--color-white);
  transition: transform calc(var(--animation-step) * 2) cubic-bezier(0, -.5, .5, 4) var(--animation-step), border-radius calc(var(--animation-step) * 2) cubic-bezier(0, -.75, .5, 2) var(--animation-step), background-color calc(var(--animation-step) * 1) ease var(--animation-step), color calc(var(--animation-step) * 2) ease var(--animation-step), box-shadow calc(var(--animation-step) * 2) ease calc(var(--animation-step) * 2);
  box-shadow: 0 0 0 1px color-mix(in hsl, var(--color-foreground) 80%, var(--color-user-background) 30%);
  border-radius: calc(var(--border-radius) / 12);
  color: var(--color-foreground);
  transform: scale(1.01);
}

main .l00k__nav ul li a:hover abbr {
  opacity: 0;
  transition: opacity calc(var(--animation-step) * 2), transform calc(var(--animation-step) * 1) cubic-bezier(0, -.25, .5, 2) calc(var(--animation-step) * 2);
}

main .l00k__nav ul li a:hover abbr:first-of-type, main .l00k__nav ul li a:hover abbr:nth-of-type(2) {
  transform: translateX(0);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

article {
  padding: var(--space);
  gap: var(--space);
  gap: calc(var(--space) / 1);
  grid-template-rows: 0 1fr;
  display: grid;
  position: relative;
}

article header {
  margin-top: calc(-1 * var(--space) * 1) auto 0;
}

article section {
  z-index: 2;
  padding: var(--space);
  transition-timing-function: easeInOut;
  animation-name: fadeIn;
  animation-delay: .5s;
  animation-duration: calc(var(--animation-step) * 3);
  animation-fill-mode: both;
  position: relative;
}

article section:first-of-type {
  margin-top: calc(-1 * var(--space) * 1);
  animation-delay: .25s;
}

article section > * > * {
  transition-timing-function: easeInOut;
  animation-name: fadeIn;
  animation-delay: .9875s;
  animation-duration: calc(var(--animation-step) * 2);
  animation-fill-mode: both;
}

article section > * > :first-of-type {
  animation-delay: .875s;
}

main.l00k.--bi {
  container-type: inline-size;
}

main.l00k.--bi article {
  padding: var(--space);
  gap: var(--space);
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  display: grid;
  position: relative;
}

main.l00k.--bi article header {
  z-index: 111111;
  width: 100%;
  position: absolute;
}

main.l00k.--bi article section {
  z-index: 2;
  padding: var(--space);
  grid-column: span 1;
  position: relative;
}

main.l00k.--bi article section:first-of-type {
  margin-top: unset;
}

main.l00k.--bi.w__l00k__nav {
  container-type: unset !important;
}

main.l00k.--bi.--slider {
  container-type: inline-size;
}

main.l00k.--bi.--slider article {
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

main.l00k.--bi.--slider article header {
  z-index: 111111;
  width: 100%;
  position: absolute;
}

main.l00k.--bi.--slider article section {
  z-index: 2;
  padding: var(--space);
  grid-column: span 1;
  position: relative;
}

main.l00k.--bi.--slider article section:first-of-type {
  margin-top: unset;
}

main.l00k.--bi.--slider article section:nth-of-type(3) {
  margin-top: unset;
  padding: var(--space) 0;
  grid-area: 2 / span 2;
}

main.l00k.--bi.--slider.w__l00k__nav {
  container-type: unset !important;
}

main.l00k.--bi.--slider-vert {
  container-type: inline-size;
}

main.l00k.--bi.--slider-vert article {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr min-content;
  display: grid;
}

main.l00k.--bi.--slider-vert article header {
  z-index: 111111;
  width: 100%;
  position: absolute;
}

main.l00k.--bi.--slider-vert article section {
  z-index: 2;
  padding: var(--space);
  grid-column: span 1;
  position: relative;
}

main.l00k.--bi.--slider-vert article section:first-of-type {
  margin-top: unset;
}

main.l00k.--bi.--slider-vert.w__l00k__nav {
  container-type: unset !important;
}

@container (width <= 699px) {
  .l00k.--bi article {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }

  main.l00k.--bi.--slider article {
    grid-template-rows: 1fr 1fr min-content;
    grid-template-columns: 1fr;
    display: grid;
  }

  main.l00k.--bi.--slider article section:nth-of-type(3) {
    margin-top: unset;
    grid-column: unset;
    grid-row: unset;
    grid-area: unset;
    padding-top: inherit;
    padding-left: 0;
    padding-right: 0;
    padding: var(--space) 0;
  }
}

@container (width <= 700px) {
  .look.__visions article .__player.__hand #hand {
    top: -35%;
    left: -8%;
    width: 12em !important;
  }

  .l00k.--hand--c article .__player.__hand #hand {
    left: -8%;
    top: unset;
    bottom: 0;
    width: 12em !important;
  }
}

.item__shell {
  z-index: 111;
  background: none;
  place-content: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.item__shell > div {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.item__shell:before {
  content: "";
  z-index: 0;
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
  transition: transform calc(var(--animation-step) * 2) cubic-bezier(0, -.5, .5, 1), background-color calc(var(--animation-step) * 2) ease, color calc(var(--animation-step) * 2) ease, box-shadow calc(var(--animation-step) * 2) ease;
  background: 0 0 / 150%;
  display: block;
  position: absolute;
  box-shadow: 0 0 0 calc(var(--border-width) * 1.125) var(--color-foreground) !important;
  box-shadow: 0 0 0 calc(var(--border-width) * 1.125) color-mix(in srgb, green 100%, var(--color-foreground) 85%) !important;
}

.item__shell.active:after {
  transform: scale(1.05);
}

.hm__control {
  z-index: 11111;
  max-width: 40em;
  display: block;
  position: relative;
}

@media only screen and (width <= 799px) and (orientation: landscape) {
  .hm__control {
    max-width: 20em !important;
  }
}

#hand {
  background: none;
  border: 0;
  outline: none;
  display: block;
}

#hand span {
  transform-origin: center;
  align-items: center;
  height: 100%;
  display: flex;
  transform: scale(1.5);
}

#hand span svg#theHand {
  height: 100%;
  max-width: var(--screen-sm);
  width: auto !important;
}

#hand span svg#theHand g#FingersPalmBg {
  visibility: hidden;
}

.__player.__hand #hand {
  z-index: 22222;
  width: clamp(10em, 30vmin, 20em);
  height: auto;
  position: absolute;
  top: -20em;
  left: 4em;
}

@media only screen and (width >= 769px) {
  .__player.__hand #hand {
    top: -21em;
  }
}

.__player.__hand #hand span svg#theHand .st0 {
  fill: none;
  stroke: var(--color-foreground);
  stroke-width: 3px;
  stroke-miterlimit: 10;
}

.__player.__hand #hand span svg#theHand circle, .__player.__hand #hand span svg#theHand rect {
  fill: var(--color-foreground);
}

.__player.__hand #hand span svg#theHand rect {
  stroke: var(--color-foreground);
  stroke-width: .0675em;
  stroke-linejoin: round;
}

.__player.__hand #hand span svg#theHand #FingersPalm {
  filter: url("#goo2");
}

.__player.__hand #hand span svg#theHand g#FingersPalmBg {
  visibility: visible;
}

.__player.__hand #hand span svg#theHand g#FingersPalmBg path {
  fill: var(--color-background);
  stroke-width: 0;
}

.l00k.--hand--c article .__player.__hand #hand {
  left: calc(-1 * var(--space) * 0);
  top: unset;
  bottom: calc(-1 * var(--space) * 2);
}

@media only screen and (width >= 769px) {
  .l00k.--hand--c article .__player.__hand #hand {
    bottom: calc(-1 * var(--space) * .75);
    left: calc(1 * var(--space) * 1.5);
  }
}

:root {
  --color-foreground: #300808;
  --color-background: #fcfcfc;
  --color-primary: #8f00fc;
  --color-secondary: #3cf812;
  --color-tertiary: #bb20fe;
  --color-quartinary: #3c4253;
  --color-black: #000;
  --color-white: #fff;
  --color-red: #f35;
  --color-green: #41df26;
  --color-yellow: #ffe100;
  --color-green--a05: #68e552bf;
  --color-green--a025: #68e55220;
  --color-shadow: #e2dfd94d;
  --color-p: #222b3a;
  --color-foreground--l95: #f8f4f2;
  --color-foreground--l90: #f3ebe7;
  --color-foreground--l80: #dec9bf;
  --color-foreground--l60: #c5b0a5;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-mono: monospace, monospace;
  --font-size: 16px;
  --font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-line-height-body: 1.375em;
  --font-line-height-heading: 1.25em;
  --font-line-height-lhm-heading: 1.125em;
  --space: calc(var(--font-size) * 1.5);
  --screen-xl: 1500px;
  --screen-lgg: 1260px;
  --screen-lg: 1060px;
  --screen-md: 800px;
  --screen-sm: 480px;
  --animation-step: .125s;
  --input-box-shadow: 0 0 0em 1em var(--foreground-color);
  --focus-box-shadow: 0 0 0 1.375em var(--primary-color);
  --focus-box-shadow-color: var(--primary-color);
  --border-width: 1px;
  --frame: var(--color-primary-lp1);
  --border-radius: calc(var(--space) * 1);
  --color-column-1: #b6b2aa;
  --color-column-2: #c0bbb4;
  --color-column-3: #d6d3cc;
  --color-column-4: #b9b4ac;
  --color-column-5: #b0aba0;
  --color-column-dark: #146105;
  --color-column-accent: #655fd3;
  --color-column-highlight: #da6a3e;
}

@media (width >= 400px) {
  :root {
    --space: calc(var(--font-size) * 1.5);
  }
}

@media (width >= 800px) {
  :root {
    --space: calc(var(--font-size) * 1.875);
  }
}

@media (width >= 1060px) {
  :root {
    --space: calc(var(--font-size) * 2);
  }
}

path.favIcon {
  fill: #000;
}

@media (prefers-color-scheme: dark) {
  path.favIcon {
    fill: #fff;
  }
}

:root {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.375em;
}

html {
  background: var(--color-background);
  color: var(--color-foreground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: var(--font-family);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html, body {
  font-size: 16px;
  position: relative;
}

* {
  box-sizing: border-box;
}

:before, :after {
  box-sizing: inherit;
}

body, main, article {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

main, header {
  margin: 0 auto;
}

main article {
  overflow: scroll;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main {
  margin: 0 auto;
}

img {
  max-width: 100%;
  height: auto;
}

#l00k__fan {
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
}

#l00k__fan span {
  aspect-ratio: 1;
  width: clamp(10em, 40%, 40em);
  display: block;
}

#l00k__fan span svg.fanIll {
  width: 100%;
  height: 100%;
}

#l00k__fan span svg.fanIll .fan-movement {
  transform-origin: center;
  will-change: transform;
}

#l00k__fan span svg.fanIll path {
  fill: var(--color-black);
}

#l00k__fan span svg.fanIll #circleBG {
  opacity: 0;
}

#l00k__fan span:hover {
  cursor: pointer;
}

@container (width <= 699px) {
  #loop.__fan .bar #left, #loop.__fan .bar #right {
    width: clamp(10em, 60%, 30em);
  }
}

main {
  height: 100dvh;
  display: grid;
  overflow: hidden;
}

main .l00k__nav {
  align-content: start;
}

main .l00k__nav h1 {
  filter: url("#goo2");
}

main button.toggle__l00k__nav {
  top: unset;
  right: calc(var(--space) * 1.675);
  bottom: calc(var(--space) * 1.675);
  position: absolute;
}

main.w__l00k__nav {
  --right: 22em;
}

main.w__l00k__nav button.toggle__l00k__nav {
  width: 2.5em;
  height: 2.5em;
  right: calc(var(--space) * .5);
  top: calc(var(--space) * 1);
  bottom: unset;
  border-radius: .125em;
  padding: 1.5em;
  position: fixed;
}

main.w__scroll article {
  overflow: hidden scroll;
}

.l00k__body {
  grid-template-columns: 1fr;
  display: grid;
  position: relative;
  container-type: inline-size;
}

.l00k__body .l00k__name {
  align-items: center;
  margin-bottom: 0;
  display: grid;
}

.l00k__body .l00k__name > * {
  filter: url("#goo3");
  text-align: center;
  letter-spacing: .15em;
  margin-bottom: 0;
  padding-top: .75em;
  padding-bottom: .375em;
  font-weight: 400;
  line-height: 1.375em;
}

.l00k__body #l00k__head {
  justify-items: center;
  width: 100%;
  display: grid;
}

.l00k__body #l00k__head > * {
  max-width: clamp(30em, 60vmin, 100em);
  margin: 0 auto;
  display: block;
}

.l00k__body #l00k__cols > * {
  transform-origin: center;
  max-width: clamp(30em, 90vw, 80em);
  margin: 0 auto;
  display: block;
  transform: scale(1.075);
}

.l00k__body #l00k__fan {
  margin-top: -1em;
  margin-bottom: -2em;
}

@container (width >= 699px) {
  .l00k__body {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }

  .l00k__body .l00k__name > * {
    text-align: left;
    padding-top: 0;
    padding-left: .5em;
  }

  .l00k__body #l00k__head > * {
    margin: -5vh auto;
  }

  .l00k__body #l00k__fan {
    margin-top: unset;
    margin-bottom: unset;
  }

  .l00k__body #l00k__fan > * {
    margin-right: -1em;
  }

  .l00k__body > :nth-of-type(3) {
    grid-area: 1 / 2 / auto / 3;
    display: grid;
    place-items: end !important;
  }

  .l00k__body > :nth-of-type(2) {
    grid-area: 2 / 1 / auto / span 2;
    width: 100%;
  }

  .l00k__body > :nth-of-type(2) > * {
    width: 100%;
    margin-bottom: -2em;
  }

  .l00k__body > :nth-of-type(4) {
    grid-area: 3 / 1 / auto / span 2;
    width: 100%;
  }
}

section.l00k__address {
  text-align: center;
  border: 1px solid var(--color-foreground);
  margin: var(--space) auto clamp(var(--space) * 2, 5vmin, var(--space) * 2);
  width: calc(100% - var(--space) * 2);
  border-top-color: color-mix(in srgb, var(--color-foreground) 50%, transparent 100%);
  border-left-color: color-mix(in srgb, var(--color-foreground) 50%, transparent 100%);
  max-width: clamp(30em, 90vw, 80em);
}

section.l00k__address [data-heading] {
  filter: url("#goo3");
  font-family: var(--font-mono);
  margin-bottom: .125em;
}

section.l00k__address a {
  color: var(--color-foreground);
  text-underline-offset: .1em;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-foreground);
  text-decoration-color: var(--color-foreground);
  transition: all calc(var(--animation-step) * .75) cubic-bezier(.215, .61, .355, 1);
}

section.l00k__address a:hover {
  color: var(--color-primary);
  text-underline-offset: .25em;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-primary);
  text-decoration-color: var(--color-primary);
}

#colTops path:not([class*="columns__svg__cols"]):not([class*="gradient"]) {
  fill: var(--color-column-dark);
}

.l00k__cols {
  z-index: 1;
  position: relative;
}

.columns__svg__cols--0 {
  fill: var(--color-column-1);
}

.columns__svg__cols--1 {
  fill: var(--color-column-2);
}

.columns__svg__cols--2 {
  fill: var(--color-column-3);
}

.columns__svg__cols--3 {
  fill: var(--color-column-4);
}

.columns__svg__cols--4 {
  fill: var(--color-column-5);
}

.columns__svg__cols--5 {
  fill: url("#columns__svg--gradient--1");
}

.columns__svg__cols--6 {
  fill: url("#columns__svg--gradient--2");
}

.columns__svg__cols--7 {
  fill: url("#columns__svg--gradient--3");
}

.columns__svg__cols--8 {
  fill: url("#columns__svg--gradient--4");
}

.columns__svg__cols--9 {
  fill: url("#columns__svg--gradient--5");
}

.columns__svg__cols--10 {
  fill: url("#columns__svg--gradient--6");
}

.columns__svg__cols--11 {
  fill: url("#columns__svg--gradient--7");
}

.columns__svg__cols--12 {
  fill: url("#columns__svg--gradient--8");
}

.columns__svg__cols--13 {
  fill: url("#columns__svg--gradient--9");
}

.gradient-stop-1 {
  stop-color: var(--color-white);
  stop-opacity: 1;
}

.gradient-stop-2 {
  stop-color: var(--color-column-accent);
  stop-opacity: 0;
}

.gradient-stop-3 {
  stop-color: var(--color-column-highlight);
  stop-opacity: 1;
}

.gradient-stop-4 {
  stop-color: var(--color-column-accent);
  stop-opacity: 0;
}

.gradient-stop-5 {
  stop-color: var(--color-column-dark);
  stop-opacity: 1;
}

.gradient-stop-6 {
  stop-color: var(--color-column-accent);
  stop-opacity: 0;
}

#columns__svg path:not([class*="columns__svg__cols"]) {
  fill: var(--color-column-dark);
}

.leftCols__color, .rightCols__color {
  transition: opacity 1.125s;
}

stop {
  stop-opacity: 1;
}

#l00k__head {
  z-index: 2;
  width: 100%;
  max-width: 900px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

#l00k__head > span {
  width: 100%;
  display: block;
  position: relative;
}

#l00k__head svg {
  width: 100%;
}

#l00k__head svg .headQuarters path {
  stroke: #0000;
  fill: #0000;
}

.dailGroup {
  opacity: 1;
}

.dailBack {
  fill: none;
}

.northDail {
  fill: var(--color-white);
}

.southDail {
  fill: color-mix(in srgb, var(--color-foreground) 33%, var(--color-background));
}

.fanCol #l00k__head .headQuarters {
  visibility: hidden !important;
}

.fanCol #l00k__head .conduitHead {
  fill: #fff !important;
}

.fanCol #l00k__head .northDail {
  fill: color-mix(in srgb, var(--color-green) 80%, var(--color-black));
}

.fanCol #l00k__head .dailPin {
  fill: #fff !important;
}

.fanCol #l00k__head .southDail {
  fill: color-mix(in srgb, var(--color-foreground) 33%, var(--color-background));
  opacity: .125;
}
/*# sourceMappingURL=luc.d64041f5.css.map */
