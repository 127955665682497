@import "app";

video::-webkit-media-controls {
    display: none !important;
    opacity: 0;
}

video::-webkit-media-controls-start-playback-button {
    display: none !important;
}

* {
    -webkit-tap-highlight-color: transparent !important;
}

html {
    overflow: hidden;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// L00k Nav Layout 
main {
    --right: 0;
    --color-user-background: rgb(0, 0, 2);
    position: fixed;
    overflow: hidden;
    // min-height: 100dvh;
    width: 100%;
    display: grid;
    grid-template-columns: auto var(--right);
    transition:
        grid-template-columns calc(var(--animation-step) * 1.5) cubic-bezier(0, 0.25, 0.25, 1.2) 0s,
        opacity calc(var(--animation-step) * 1) ease 2s;

    // >* {
    //     grid-column: content;
    // }

    @media only screen and (max-width: 699px) {
        grid-template-columns: 100% var(--right);
    }


    .l00k__nav {
        width: 100%;
        margin: 0;
        padding: 0;
        overflow-y: scroll;
        overflow-x: hidden;

        >* {
            transition: opacity calc(var(--animation-step) * 1.25) ease 0s;
            opacity: 0;
            width: 100%;
        }

        @media only screen and (max-width: 699px) {
            transition: transform .3s ease-in-out;
            top: 0;
            bottom: 0;
            min-height: 100vh;
            min-height: 100dvh;
            /* override Safari bug */
            position: fixed;
            /* or choose `absolute` depending on desired behavior*/
            width: 300px;
            left: -340px;
            border-left: 1px solid transparent !important;
        }
    }

    &.w__l00k__nav {
        --right: 19em;
        will-change: grid-template-columns, opacity;
        height: 100dvh;

        @media only screen and (max-width: 699px) {
            // grid-template-columns: auto 100%;
        }

        @media only screen and (min-width: 1400px) {
            --right: 26em;
        }

        transition: grid-template-columns calc(var(--animation-step) * 3) cubic-bezier(0, 0.25, 0.25, 1.2) 0s,
        opacity calc(var(--animation-step) * 1) ease 3s;

        .l00k__nav {
            @media only screen and (max-width: 699px) {
                position: absolute;
                left: 0;
                width: 100%;
                z-index: 11111;
            }

            >* {
                transition: opacity calc(var(--animation-step) * 1) ease 0.2875s;
                opacity: 1;
            }
        }

        // Move this to Lunar Template
        .calendar__heading {
            display: none;
        }
    }

    &.w__stacked {
        @media only screen and (max-width: 699px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr min-content;
            height: inherit;
            overflow-y: scroll;

            article {
                min-height: 92dvh;
            }
        }

        .l00k__nav {
            @media only screen and (max-width: 699px) {
                position: relative;
                left: 0;
                width: 100%;
                z-index: 11;
                min-height: 50dvh;
            }

            >* {
                transition: opacity calc(var(--animation-step) * 1) ease 0.2875s;
                opacity: 1;
            }
        }
    }

    &.wo__l00k__nav {
        grid-template-columns: 1fr;
        height: 100dvh;

        @media only screen and (max-width: 699px) {
            grid-template-columns: 1fr;
            grid-template-rows: min-content;
            height: inherit;
            overflow-y: scroll;

            article {
                min-height: 92dvh;
            }
        }

        .l00k__nav {
            border: none;

            @media only screen and (max-width: 699px) {
                position: relative;
                left: 0;
                width: 100%;
                z-index: 11;
                min-height: 50dvh;
            }

            >* {
                transition: opacity calc(var(--animation-step) * 1) ease 0.2875s;
                opacity: 1;
            }
        }
    }

    &.w__scroll {

        article {
            overflow: scroll;
        }

        @media only screen and (max-width: 699px) {
            grid-template-columns: 1fr;
            grid-template-rows: min-content min-content;
            height: 100dvh;
            overflow-y: hidden;

            article {
                min-height: unset;
                height: 100dvh;
                overflow-y: scroll;
            }
        }

        .l00k__nav {
            @media only screen and (max-width: 699px) {
                position: relative;
                left: 0;
                width: 100%;
                z-index: 11;
                min-height: 50dvh;
            }

            >* {
                transition: opacity calc(var(--animation-step) * 1) ease 0.2875s;
                opacity: 1;
            }
        }
    }

}

main.l00k header.l00k__navigation {
    width: 100%;
    z-index: 111111;
    position: absolute;
}

// Look Nav Toggle Button
button.toggle__l00k__nav {
    @media only print {
        display: none;
    }

    @extend .hm-button, .hm-button--dope, .--xl, .hm-button--icon-only, .--round, .--outline;
    margin-left: calc(-1 * calc(var(--space) / 2));
    margin-top: calc(-1 * calc(var(--space) / 2));
    background-color: white !important;
    border-color: color-mix(in srgb, var(--color-user-background) 40%, var(--color-foreground) 95%) !important;
    color: var(--color-foreground);
    z-index: 111;
    right: calc(var(--space) * 0.375);
    top: calc(var(--space) * 0.875);
    position: absolute;
    transition: background-color 0.5s ease,
    transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.125s;
    overflow: hidden;

    span {
        color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 90%);

        text-align: center;
        font-weight: 800;
        transform: scale(2.5) translate(0.01em, -0.03675em);
        transform-origin: center;

        abbr.elips {
            font-size: 16px;
            width: 0.25rem;
            height: 0.25em;
            background-color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 90%);
            display: block;
            position: relative;
            margin-top: 0.0675em;
            border-radius: 111em;
            transform: rotate(-65deg);
            transform: scale(1);
            transition:
                transform 2s cubic-bezier(0.075, 0.82, 0.165, 1),
                opacity 0.125s ease 0.25s;

            &::after,
            &::before {
                content: '';
                position: absolute;
                transform-origin: center;
                width: 0.25em;
                height: 0.25em;
                background-color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 90%);
                display: block;
                left: -0.375em;
                border-radius: 111em;
                transform: scale(1);
                transition:
                    transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2875s,
                    opacity 0.125s ease 0s;
            }

            &::after {
                left: unset;
                right: -0.375em;
            }
        }
    }

    .profile__img {
        transition: opacity calc(var(--animation-step) * 3) ease 0.5s, transform var(--animation-step) ease 0s;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: block;
        pointer-events: none;
    }

    abbr.closeIcon {

        &::after,
        &::before {
            opacity: 0;
            background-color: color-mix(in srgb, var(--color-user-background) 50%, var(--color-foreground) 100%);
            transition:
                transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1) 0.125s,
                opacity 0.125s ease 0s;
        }
    }

    &[aria-expanded="true"] {
        // border-color: color-mix(in srgb, var(--color-user-background) 40%, var(--color-foreground) 60%) !important;

        // transform: translate(3.5em, -0.25em) scale(0.875) rotate(360deg);
        transform: scale(0.875) translate(0.125em, -0.25em);
        // transform: scale(.875)translate(calc(var(--space) * 1.25), -0.25em);

        @media only screen and (max-width: 699px) {
            left: -0.375em;
            top: -0.375em;
            transform: scale(0.5);

            abbr.closeIcon {

                &::after,
                &::before {
                    height: 3px;
                }
            }

        }

        .profile__img {
            opacity: 0;
            transition:
                transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s,
                opacity 0.25s ease 0.375s;
        }

        abbr.elips {
            transform: scale(0);
            opacity: 0;
            transition:
                transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
                opacity 0.25s ease 0.25s;

            &::after,
            &::before {
                transform: scale(0);

                transition:
                    transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
                    opacity 0.5s ease 0.5s;
            }

        }

        abbr.closeIcon {

            &::after,
            &::before {
                opacity: 1;
                transition:
                    transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
                    opacity 0.125s ease 0.25s;
            }

            &:before {
                transform: translateY(+0.25em) rotate(-45deg);
            }

            &:after {
                transform: translateY(-0.25em) rotate(45deg);
            }
        }
    }


    &:hover {
        .profile__img {
            transition: transform var(--animation-step) cubic-bezier(.79, -0.48, .25, 1) 0s;
            transform: scale(1.0375);

        }

        abbr.closeIcon {

            &:before {

                transform: translateY(+0.25em) rotate(-225deg) scaleX(0.8);
            }

            &:after {

                transform: translateY(-0.25em) rotate(225deg) scaleX(0.8);
            }
        }

        span {
            abbr {
                transform: rotate(-25deg);
            }
        }

    }

}


// L00K Nav default Theme
main .l00k__nav {
    background-color: var(--color-background);
    border-left: 1px solid color-mix(in srgb, var(--color-user-background) 70%, var(--color-foreground) 95%);
    display: grid;
    align-items: start;

    >div {
        padding: var(--space);
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        justify-items: center;
        text-align: center;
        gap: calc(var(--space) * 2);
    }

    & .l00k__author {
        background-color: var(--color-user-background);
        background-color: color-mix(in srgb, var(--color-user-background) 100%, var(--color-foreground) 15%);
        border: 1px color-mix(in hsl, var(--color-user-background), white 0%) solid;
        // box-shadow: 0 0 0em 1px color-mix(in hsl, var(--color-background) 0%, var(--color-foreground) 100%);
        display: grid;
        align-content: center;
        // background-image: url(../images/mud.png);
        background-size: cover;
        background-position: center;
        image-rendering: pixelated;
        border-radius: 0.125em;
        overflow: hidden;
        min-width: 10em;
        margin: 0 auto 1em;
        align-self: flex-start;
        max-width: 90vmin;

        & span {

            text-align: center;
            font-weight: 800;
            transform: scale(5) translate(0.01em, -0.03675em);
            transform-origin: center;

        }

        & svg {
            // filter: url(#goo2);
            width: 100%;
            height: auto;
        }
    }

    h1 {
        text-transform: uppercase;
        letter-spacing: 0.0675em;
        filter: url(#goo3);
        color: var(--color-foreground);
        font-size: 1.875em;
        // margin-bottom:  var(--space);
        font-family: var(--font-mono);
        text-align: center;
        // text-align: justify;
        padding-left: 0.5em;
        padding-right: 0.5em;
        align-self: flex-start;
        margin-bottom: auto;

        &::selection,
        &::-moz-selection {
            background: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 80%) !important;
        }
    }

    .l00k__brand {
        font-family: var(--font-mono);
        align-self: end;
        font-weight: 300;
        font-size: 0.875em;
        letter-spacing: 0.125em;
        margin: 0 auto var(--space);
        padding: 0.25em 0.4375em;
        font-size: 0.75em;
        text-align: justify;
        line-height: 1;
        width: auto;
        opacity: 0.5;
        border-radius: 0.25em;
        // filter: url(#goo2);
        // background-color: color-mix(in srgb, var(--color-foreground) 5%, transparent 100%);
    }

    ul {
        margin: 0 auto calc(var(--space) * 2);
        padding: 0 var(--space);
        width: 100%;
        justify-self: start;
        max-width: 700px;

        li {
            margin: 0 0 calc(var(--space) / 2.25);
            padding: 0;

            a {
                // font-family: var(--font-mono);
                font-size: 1em;
                // text-transform: uppercase;
                letter-spacing: 0.25em;
                line-height: 1.375;
                font-weight: 400;
                border-radius: calc(var(--border-radius) / 2);
                display: grid;
                grid-template-columns: min-content 1fr min-content;
                background-color: var(--color-white);
                background-color: transparent;
                color: color-mix(in srgb, var(--color-user-background) 100%, var(--color-foreground) 95%);
                box-shadow: 0 0 0em 1px color-mix(in srgb, var(--color-user-background) 0%, transparent 100%);
                gap: 0.5em;
                transition:
                    transform calc(var(--animation-step) * 2) cubic-bezier(0, -0.5, 0.5, 4) var(--animation-step),
                    border-radius calc(var(--animation-step) * 2) cubic-bezier(0, -0.75, 0.5, 2) var(--animation-step),
                    background-color calc(var(--animation-step) * 1) ease var(--animation-step),
                    color calc(var(--animation-step) * 2) ease var(--animation-step),
                    box-shadow calc(var(--animation-step) * 2) ease 0s;

                abbr {
                    transition:
                        transform calc(var(--animation-step) * 2) ease;
                    opacity: 1;
                    width: 0.25em;
                    height: 0.25em;
                    background-color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 80%);
                    border-radius: calc(var(--border-radius) / 1);
                    margin: 0 calc(var(--sapce) * 2) 0 var(--sapce);

                    transform: translateX(-1.25em);

                    &:nth-of-type(2) {
                        transform: translateX(1.25em);
                    }
                }

                &:hover {
                    // color: color-mix(in hsl, var(--color-background) 30%, white 100%);
                    background-color: var(--color-white);
                    transform: scale(1.01);

                    transition:
                        transform calc(var(--animation-step) * 2) cubic-bezier(0, -0.5, 0.5, 4) var(--animation-step),
                        border-radius calc(var(--animation-step) * 2) cubic-bezier(0, -0.75, 0.5, 2) var(--animation-step),
                        background-color calc(var(--animation-step) * 1) ease var(--animation-step),
                        color calc(var(--animation-step) * 2) ease var(--animation-step),
                        box-shadow calc(var(--animation-step) * 2) ease calc(var(--animation-step) * 2);


                    box-shadow: 0 0 0 1px color-mix(in hsl, var(--color-foreground) 80%, var(--color-user-background) 30%);
                    border-radius: calc(var(--border-radius) / 12);
                    color: var(--color-foreground);

                    abbr {
                        opacity: 0;
                        transition: opacity calc(var(--animation-step) * 2), transform calc(var(--animation-step) * 1) cubic-bezier(0, -0.25, 0.5, 2) calc(var(--animation-step) * 2);
                    }

                    abbr:nth-of-type(1) {
                        // background-color: var(--color-background);
                        transform: translateX(0);
                    }

                    abbr:nth-of-type(2) {
                        transform: translateX(0);
                    }
                }
            }
        }
    }


}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// One Section
article {
    display: grid;
    grid-template-rows: 0 1fr;
    padding: var(--space);
    gap: var(--space);
    position: relative;
    gap: calc(var(--space) / 1);

    header {
        margin-top: calc(-1 * calc(var(--space) * 1)) auto 0;
    }

    section {
        z-index: 2;
        padding: var(--space);
        position: relative;

        animation-delay: 0.5s;
        animation-name: fadeIn;
        transition-timing-function: easeInOut;
        animation-duration: calc(var(--animation-step) * 3);
        animation-fill-mode: both;

        &:nth-of-type(1) {
            animation-delay: 0.25s;
            margin-top: calc(-1 * calc(var(--space) * 1));
        }

        > * > * {
            animation-delay: 0.9875s;
            animation-name: fadeIn;
            transition-timing-function: easeInOut;
            animation-duration: calc(var(--animation-step) * 2);
            animation-fill-mode: both;

            &:nth-of-type(1) {
                animation-delay: 0.875s;
            }
        }
       
    }
}

// Two Sections

main.l00k.--bi {
    container-type: inline-size;

    article {

        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        padding: var(--space);
        gap: var(--space);
        position: relative;
        // height: 100vh;
        // height: 100dvh;

        header {
            // margin-top: calc(-1 * var(--space) * 1) auto 0;
            width: 100%;
            position: absolute;
            z-index: 111111;
        }

        section {
            grid-column: span 1;
            // grid-row: span 2;
            z-index: 2;
            padding: var(--space);
            position: relative;

            &:nth-of-type(1) {
                // margin-left: calc(-1 * calc(var(--space) * 1));
                margin-top: unset;
            }

        }
    }

    &.w__l00k__nav {
        container-type: unset !important;


    }
}

main.l00k.--bi.--slider {
    container-type: inline-size;

    article {
        display: grid;
        grid-template-rows: 1fr min-content;
        grid-template-columns: 1fr 1fr;

        header {
            // margin-top: calc(-1 * var(--space) * 1) auto 0;
            width: 100%;
            position: absolute;
            z-index: 111111;
        }

        section {
            grid-column: span 1;
            // grid-row: span 2;
            z-index: 2;
            padding: var(--space);
            position: relative;

            &:nth-of-type(1) {
                // margin-left: calc(-1 * calc(var(--space) * 1));
                margin-top: unset;
            }

            &:nth-of-type(3) {
                // margin-left: calc(-1 * calc(var(--space) * 1));
                margin-top: unset;
                grid-column: span 2;
                grid-row: 2;
                padding: var(--space) 0;
            }
        }
    }

    &.w__l00k__nav {
        container-type: unset !important;
    }
}

main.l00k.--bi.--slider-vert {
    container-type: inline-size;

    article {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr min-content;

        header {
            // margin-top: calc(-1 * var(--space) * 1) auto 0;
            width: 100%;
            position: absolute;
            z-index: 111111;
        }

        section {
            grid-column: span 1;
            // grid-row: span 2;
            z-index: 2;
            padding: var(--space);
            position: relative;

            &:nth-of-type(1) {
                // margin-left: calc(-1 * calc(var(--space) * 1));
                margin-top: unset;
            }

            // &:nth-of-type(3) {
            //     // margin-left: calc(-1 * calc(var(--space) * 1));
            //     margin-top: unset;
            //     grid-column: span 2;
            //     grid-row: 2;
            // }
        }
    }

    &.w__l00k__nav {
        container-type: unset !important;
    }
}

@container (max-width: 699px) {
    .l00k.--bi article {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }

    main.l00k.--bi.--slider article {
        grid-template-columns: 1fr;
        display: grid;
        grid-template-rows: 1fr 1fr min-content;

        & section:nth-of-type(3) {
            // margin-left: calc(-1 * calc(var(--space) * 1));
            margin-top: unset;
            grid-column: unset;
            grid-row: unset;
            grid-area: unset;
            padding-top: inherit;
            padding-left: 0;
            padding-right: 0;
            padding: var(--space) 0;
        }
    }
}

@container (max-width: 700px) {
    .look.__visions article .__player.__hand #hand {
        left: -8%;
        width: 12em !important;
        top: -35%;
    }

    .l00k.--hand--c article .__player.__hand #hand {
        left: -8%;
        width: 12em !important;
        top: unset;
        bottom: 0;
    }
}

.item__shell {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    position: absolute;
    z-index: 111;
    display: grid;
    justify-content: center;
    align-content: center;
    overflow: visible;

    >div {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        // border-radius: 0.125em;
        background: none;
        // background-image: var(--background__one);
        background-size: 150%;
        border-radius: var(--border-radius);
        image-rendering: pixelated;
        box-shadow: 0 0 0 calc(var(--border-width) * 1.125) var(--color-foreground) !important;
        box-shadow: 0 0 0 calc(var(--border-width) * 1.125) color-mix(in srgb, green 100%, var(--color-foreground) 85%) !important;
        transition:
            transform calc(var(--animation-step) * 2) cubic-bezier(0, -0.5, 0.5, 1),
            background-color calc(var(--animation-step) * 2) ease,
            color calc(var(--animation-step) * 2) ease,
            box-shadow calc(var(--animation-step) * 2) ease;
    }

    &.active {
        &:after {
            transform: scale(1.05);
        }
    }
}




// Play button container
.hm__control {
    position: relative;
    display: block;
    z-index: 11111;
    max-width: 40em;


    @media only screen and (max-width: 799px) and (orientation : landscape) {
        max-width: 20em !important;
    }
}




// 
#hand {
    display: block;
    background: none;
    border: 0;
    outline: none;

    span {
        transform: scale(1.5);
        transform-origin: center;
        display: block;
        height: 100%;
        display: flex;
        align-items: center;

        svg#theHand {
            height: 100%;
            max-width: var(--screen-sm);
            width: auto !important;


            g#FingersPalmBg {
                visibility: hidden;
            }
        }
    }
}

.__player.__hand {

    #hand {
        position: absolute;
        top: -20em;
        width: clamp(10em, 30vmin, 20em);
        height: auto;
        z-index: 22222;
        left: 4em;

        @media only screen and (min-width: 769px) {
            top: -21em;
        }

        span {
            svg#theHand {

                .st0 {
                    fill: none;
                    stroke: var(--color-foreground);
                    stroke: var(--color-foreground);
                    stroke-width: 3px;
                    stroke-miterlimit: 10;
                }

                circle,
                rect {
                    fill: var(--color-foreground);
                }

                rect {
                    stroke: var(--color-foreground);
                    stroke-width: 0.0675em;
                    stroke-linejoin: round;
                }

                #FingersPalm {
                    filter: url(#goo2);
                }

                g#FingersPalmBg {
                    visibility: visible;

                    & path {
                        fill: var(--color-background);
                        stroke-width: 0;
                    }
                }

            }
        }
    }
}

.l00k.--hand--c article .__player.__hand #hand {
    left: calc(-1 * calc(var(--space) * 0));
    top: unset;
    bottom: calc(-1 * calc(var(--space) * 2));

    @media only screen and (min-width: 769px) {
        bottom: calc(-1 * calc(var(--space) * 0.75));
        left: calc(1 * calc(var(--space) * 1.5));
    }
}

@media(hover: none) {
    // button#hand:hover {
    //     transform:unset!important;
    // }
}