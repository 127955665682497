/* Debug style - only target paths without classes */
#colTops path:not([class*="columns__svg__cols"]):not([class*="gradient"]) {
	fill: var(--color-column-dark); /* Use the same color as gradient end */
}

.l00k__cols {
	z-index: 1;
	position: relative;
}

/* Base column colors */
.columns__svg__cols {
	&--0 {
		fill: var(--color-column-1);
	}
	
	&--1 {
		fill: var(--color-column-2);
	}
	
	&--2 {
		fill: var(--color-column-3);
	}
	
	&--3 {
		fill: var(--color-column-4);
	}
	
	&--4 {
		fill: var(--color-column-5);
	}

	/* Gradient fills */
	&--5 { fill: url(#columns__svg--gradient--1); }
	&--6 { fill: url(#columns__svg--gradient--2); }
	&--7 { fill: url(#columns__svg--gradient--3); }
	&--8 { fill: url(#columns__svg--gradient--4); }
	&--9 { fill: url(#columns__svg--gradient--5); }
	&--10 { fill: url(#columns__svg--gradient--6); }
	&--11 { fill: url(#columns__svg--gradient--7); }
	&--12 { fill: url(#columns__svg--gradient--8); }
	&--13 { fill: url(#columns__svg--gradient--9); }
}

/* Gradient stops */
.gradient-stop-1 { 
	stop-color: var(--color-white);
	stop-opacity: 1;
}
.gradient-stop-2 { 
	stop-color: var(--color-column-accent); 
	stop-opacity: 0; 
}
.gradient-stop-3 { 
	stop-color: var(--color-column-highlight); 
	stop-opacity: 1;
}
.gradient-stop-4 { 
	stop-color: var(--color-column-accent); 
	stop-opacity: 0; 
}
.gradient-stop-5 { 
	stop-color: var(--color-column-dark); 
	stop-opacity: 1;
}
.gradient-stop-6 { 
	stop-color: var(--color-column-accent); 
	stop-opacity: 0; 
}

/* Default fill for any unspecified paths */
#columns__svg path:not([class*="columns__svg__cols"]) {
	fill: var(--color-column-dark);
}

/* Animation-related classes */
.leftCols__color, .rightCols__color {
	transition: opacity 1.125s;
}

/* Ensure all gradient stops have a fill */
stop {
	stop-opacity: 1;
}